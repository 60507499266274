import React, { ComponentType } from "react";
import { RevealIcon } from "./SvgLibrary/RevealIcon";
import { getInitials } from "../../utils/users";
import { User } from "@auth0/auth0-react";
import { useAbility } from "../casl/hooks/useAbility";

export const UserAvatar: ComponentType<{
  user?: User;
  showIcon: boolean;
  size?: "small" | "regular";
}> = ({ user, showIcon, size }) => {
  const { undermapsAbility } = useAbility();
  if (!user) {
    return <></>;
  }
  return (
    <div
      className={`${
        size === "small" ? "w-7 h-7 text-sm" : "w-10 h-10"
      }  relative flex justify-center items-center rounded-full bg-gray-500 font-medium text-white uppercase`}
      data-testid="user-avatar"
    >
      {undermapsAbility.can("internal") && showIcon && (
        <RevealIcon className="w-4 h-4 absolute top-0 right-0 bg-white/100 border-2 rounded-sm border-white" />
      )}
      {getInitials(user?.name)}
    </div>
  );
};
