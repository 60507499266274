import { FC, ReactNode } from "react";
import { CanProps, useAbility } from "./hooks/useAbility";

export type VisibleProps = CanProps & {
  children?: ReactNode | (() => ReactNode);
};

/**
 * Hides children if the authorisation check fails.
 *
 * @param children
 * @param canProps CanProps object
 * @constructor
 */
export const CaslVisible: FC<VisibleProps> = ({ children, ...canProps }) => {
  const { can } = useAbility();
  const result = can(canProps);
  if (!result || children == null) {
    return null;
  }
  return <>{typeof children === "function" ? children() : children}</>;
};
