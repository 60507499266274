export type ButtonMode = "light" | "dark";
export type ButtonColor = "primary" | "secondary" | "warning" | "underdots";
export type ButtonVariant = "filled" | "outlined" | "text" | "iconOnly";
export type ButtonSize =
  | "large"
  | "regular"
  | "regular-square"
  | "small"
  | "extraSmall";
export type ButtonIconVariant = "solid" | "outline";

const BASE_STYLE =
  "flex items-center justify-center gap-1.5 h-fit font-medium whitespace-nowrap";
const FILLED_VARIANT_BASE_STYLE = `${BASE_STYLE} rounded border border-transparent min-h-fit focus:ring-2 focus:ring-offset-2  `;
const OUTLINED_VARIANT_BASE_STYLE = `${BASE_STYLE} rounded border bg-transparent`;

export const BUTTON_SIZE_CLASSNAME = {
  extraSmall: "text-xs px-1.5 py-0.5",
  small: "text-sm py-1 px-2",
  regular: "text-sm sm:text-base px-2.5 py-1.5 sm:px-4 sm:py-1.5",
  "regular-square": "px-1 py-1 sm:px-1.5 sm:py-1.5",
  large: "px-4 py-2"
};

export const ICON_SIZE_CLASSNAME = {
  extraSmall: "h-4 w-4",
  small: "h-5 w-5",
  regular: "h-6 w-6",
  "regular-square": "h-6 w-6",
  large: "h-7 w-7"
};

export const STYLE_MAP: Record<
  ButtonMode,
  Record<ButtonVariant, Record<ButtonColor, string>>
> = {
  light: {
    filled: {
      primary: `${FILLED_VARIANT_BASE_STYLE} bg-cyan-600 text-white focus:ring-cyan-500 hover:bg-cyan-700 disabled:bg-gray-200 disabled:text-gray-400`,
      secondary: `${FILLED_VARIANT_BASE_STYLE} bg-gray-100 text-black focus:ring-gray-600 hover:bg-gray-200 disabled:text-gray-400`,
      warning: `${FILLED_VARIANT_BASE_STYLE} bg-red-500 text-white focus:ring-red-500 hover:bg-red-700 disabled:bg-gray-200 disabled:text-gray-400`,
      underdots: `${FILLED_VARIANT_BASE_STYLE} bg-underdotsGreen-500 text-black focus:ring-lime-600 hover:bg-lime-500 disabled:bg-gray-200 disabled:text-gray-400`
    },
    outlined: {
      primary: `${OUTLINED_VARIANT_BASE_STYLE} text-cyan-600 border-cyan-500 disabled:text-gray-300 disabled:border-gray-300`,
      secondary: `${OUTLINED_VARIANT_BASE_STYLE} text-gray-500 border-gray-500 disabled:text-gray-300 disabled:border-gray-300`,
      warning: `${OUTLINED_VARIANT_BASE_STYLE} text-red-600 border-red-500 disabled:text-gray-300 disabled:border-gray-300`,
      underdots: `${OUTLINED_VARIANT_BASE_STYLE} text-gray-800 border-gray-800 disabled:text-gray-300 disabled:border-gray-300`
    },
    text: {
      primary: `${BASE_STYLE} text-cyan-600 disabled:text-gray-300`,
      secondary: `${BASE_STYLE} text-gray-500 disabled:text-gray-300`,
      warning: `${BASE_STYLE} text-red-600 disabled:text-gray-300`,
      underdots: `${BASE_STYLE} text-gray-800 underline underline-offset-4 disabled:text-gray-300`
    },
    iconOnly: {
      primary: `${BASE_STYLE}`,
      secondary: `${BASE_STYLE}`,
      warning: `${BASE_STYLE}`,
      underdots: `${BASE_STYLE}`
    }
  },
  dark: {
    filled: {
      primary: ` ${FILLED_VARIANT_BASE_STYLE} bg-cyan-600 text-white focus:ring-cyan-500 hover:bg-cyan-700 disabled:bg-gray-700 disabled:text-gray-500`,
      secondary: `${FILLED_VARIANT_BASE_STYLE} bg-gray-500 text-white focus:ring-gray-500 hover:bg-gray-700 disabled:bg-gray-700 disabled:text-gray-500`,
      warning: `${FILLED_VARIANT_BASE_STYLE} bg-red-500 text-white focus:ring-red-500 hover:bg-red-700 disabled:bg-gray-700 disabled:text-gray-500`,
      underdots: `${FILLED_VARIANT_BASE_STYLE} bg-underdotsGreen-500 text-black focus:ring-lime-600 hover:bg-lime-500 disabled:bg-gray-700 disabled:text-gray-500`
    },
    outlined: {
      primary: `${OUTLINED_VARIANT_BASE_STYLE} text-cyan-400 border-cyan-400 disabled:text-gray-600 disabled:border-gray-700`,
      secondary: `${OUTLINED_VARIANT_BASE_STYLE} text-gray-300 border-gray-400 disabled:text-gray-600 disabled:border-gray-700`,
      warning: `${OUTLINED_VARIANT_BASE_STYLE} text-red-500 border-red-500 disabled:text-gray-600 disabled:border-gray-700`,
      underdots: `${OUTLINED_VARIANT_BASE_STYLE} text-underdotsGreen-500 border-underdotsGreen-500 disabled:text-gray-600 disabled:border-gray-700`
    },
    text: {
      primary: `${BASE_STYLE} text-cyan-500 disabled:text-gray-600`,
      secondary: `${BASE_STYLE} text-gray-200 disabled:text-gray-600`,
      warning: `${BASE_STYLE} text-red-600 disabled:text-gray-600`,
      underdots: `${BASE_STYLE} text-underdotsGreen-500 underline underline-offset-4 disabled:text-gray-600`
    },
    iconOnly: {
      primary: `${BASE_STYLE}`,
      secondary: `${BASE_STYLE}`,
      warning: `${BASE_STYLE}`,
      underdots: `${BASE_STYLE}`
    }
  }
};

export const ICON_ONLY_STYLES: Record<
  ButtonMode,
  Record<ButtonIconVariant, Record<ButtonColor, string>>
> = {
  light: {
    solid: {
      primary:
        "fill-cyan-600 text-cyan-600 disabled:fill-gray-300 disabled:text-gray-300",
      secondary:
        "fill-gray-700 text-gray-700 disabled:fill-gray-300 disabled:text-gray-300",
      warning:
        "fill-red-600  text-red-600 disabled:fill-gray-300 disabled:text-gray-300",
      underdots:
        "fill-gray-800 text-gray-800 disabled:fill-gray-300 disabled:text-gray-300"
    },
    outline: {
      primary:
        "stroke-cyan-600 text-cyan-600 disabled:stroke-gray-300 disabled:text-gray-300",
      secondary:
        "stroke-gray-700 text-gray-700 disabled:stroke-gray-300 disabled:text-gray-300",
      warning: "stroke-red-600 text-red-700 disabled:stroke-gray-300",
      underdots:
        "fill-gray-800 text-gray-800 disabled:fill-gray-300 disabled:text-gray-300"
    }
  },
  dark: {
    solid: {
      primary:
        "fill-cyan-500 text-cyan-500 disabled:fill-gray-600 disabled:text-gray-600",
      secondary:
        "fill-gray-300 text-gray-300 disabled:fill-gray-600 disabled:text-gray-600",
      warning:
        "fill-red-600 text-red-600 disabled:fill-gray-600 disabled:text-gray-600",
      underdots:
        "fill-underdotsGreen-500 text-underdotsGreen-500 disabled:fill-gray-600 disabled:text-gray-600"
    },
    outline: {
      primary: "stroke-cyan-500 text-cyan-500 disabled:stroke-gray-600",
      secondary: "stroke-gray-300 text-gray-300 disabled:stroke-gray-600",
      warning: "stroke-red-600 text-red-600  disabled:stroke-gray-600",
      underdots:
        "stroke-underdotsGreen-500 text-underdotsGreen-500 disabled:stroke-gray-600"
    }
  }
};
