const Joi = require("joi");

const configSchema = Joi.object({
  nodeEnv: Joi.string().required(),
  environment: Joi.string().required(),
  azureEnvironment: Joi.string().required(),
  esriAppId: Joi.string().required(),
  apiBaseUrl: Joi.string().required(),
  auth0: Joi.object({
    domain: Joi.string().required(),
    clientId: Joi.string().required(),
    connection: Joi.string().required(),
    audience: Joi.string().required()
  }),
  arcgisRestApiUrl: Joi.string().required(),
  googleApiKey: Joi.string().required(),
  underdotsRecaptchaSiteKey: Joi.string().required(),
  gtm: Joi.object({
    id: Joi.string(),
    auth: Joi.string(),
    preview: Joi.string()
  }),
  featureFlags: Joi.object({
    clientInitiatedFieldJobs: Joi.boolean()
  })
});

const localEnv = process.env;

const getAzureEnvironment = (env?: string) => {
  if (env === "local") {
    env = "sandbox";
  }
  if (env === "production") {
    env = "prod";
  }
  return env;
};

const internalConfig = {
  nodeEnv: localEnv.NODE_ENV,
  environment: localEnv.REACT_APP_ENVIRONMENT,
  azureEnvironment: getAzureEnvironment(localEnv.REACT_APP_ENVIRONMENT),
  apiBaseUrl: localEnv.REACT_APP_TWIN_API_BASEURL,
  esriAppId: localEnv.REACT_APP_ESRI_APP_ID,
  auth0: {
    domain: localEnv.REACT_APP_AUTH0_DOMAIN,
    clientId: localEnv.REACT_APP_AUTH0_CLIENT_ID,
    connection: localEnv.REACT_APP_AUTH0_CONNECTION,
    audience: localEnv.REACT_APP_AUTH0_AUDIENCE
  },
  arcgisRestApiUrl: "https://revealtech.maps.arcgis.com/sharing/rest",
  googleApiKey: localEnv.REACT_APP_GOOGLE_API_KEY,
  underdotsRecaptchaSiteKey: localEnv.REACT_APP_UNDERDOTS_RECAPTCHA_SITE_KEY,
  gtm: {
    id: localEnv.REACT_APP_GTM_ID,
    auth: localEnv.REACT_APP_GTM_AUTH,
    preview: localEnv.REACT_APP_GTM_PREVIEW
  },
  featureFlags: {
    clientInitiatedFieldJobs:
      localEnv.REACT_APP_FEATURE_CLIENT_INITIATED_FIELD_JOBS ?? false
  }
};

const validationResult = configSchema.validate(internalConfig);

if (validationResult.error !== undefined) {
  throw validationResult.error;
}

export const config = validationResult.value;
